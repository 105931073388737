import React from 'react'
import { ActivityIndicator, StyleSheet, View, Text } from 'react-native'
import { TextStyles, Colors } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import { WindowResizeConsumer } from '../../context/WindowResizeContext'

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import '../../firebase.config'

/**
 *
 *  REFERENCES:
 *  https://reactnavigation.org/docs/en/auth-flow.html#docsNav
 *  https://firebase.google.com/docs/auth/web/start
 *
 **/

const AUTH = firebase.auth()

class AuthLoadingScreen extends React.Component {
    componentDidMount = async () => {
        /* DEVELOPMENT MODE */
        //this.props.navigation.navigate('Public')

        /* PRODUCTION MODE */

        await this.context.initializeUserAccount()
        this.userAuthenticationStatus()

        // clearUrlToken = () => {
        //     const { origin, pathname } = window.location
        //     window.history.replaceState(
        //         history.state,
        //         window.document.title,
        //         `${origin}${pathname}`
        //     )
        // }
    }

    componentDidUpdate = async prevProps => {
        if (prevProps.isFocused !== this.props.isFocused) {
            // Use the `this.props.isFocused` boolean
            // Call any action
            await this.context.initializeUserAccount()
            this.userAuthenticationStatus()
        }
    }

    userAuthenticationStatus = () => {
        AUTH.onAuthStateChanged(user => {
            this.props.navigation.navigate('Public')

            // if (user) {
            //     this.props.navigation.navigate('Private')
            // } else {
            //     this.props.navigation.navigate('Public')
            // }
        })
    }

    render() {
        return (
            <WindowResizeConsumer>
                {windowContext => (
                    <View
                        style={{ flex: 1 }}
                        ref={ref => {
                            this.windowContext = windowContext
                        }}
                    >
                        <UserProfileConsumer>
                            {context => {
                                return (
                                    <View
                                        style={styles.container}
                                        ref={ref => {
                                            this.context = context
                                        }}
                                    >
                                        <ActivityIndicator
                                            size="small"
                                            color={Colors.tintColor}
                                        />

                                        <Text
                                            style={[
                                                TextStyles.Logotype,
                                                { padding: 16 },
                                            ]}
                                        >
                                            Indvstry
                                        </Text>
                                        {/* <View
                                            style={{
                                                overflow: 'hidden',
                                                //backgroundColor: Colors.tintColor,
                                                borderRadius: 60,
                                                // alignSelf: Layout.isMediumDevice
                                                //     ? 'center'
                                                //     : 'flex-start',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                margin: Layout.paddingSmall,
                                                padding: Layout.paddingSmall,
                                            }}
                                        >
                                            <Image
                                                source={require('../../assets/images/icon.png')}
                                                style={{
                                                    width: Icons.XXL,
                                                    height: Icons.XXL,
                                                }}
                                                resizeMode={'cover'}
                                            />
                                        </View> */}
                                    </View>
                                )
                            }}
                        </UserProfileConsumer>
                    </View>
                )}
            </WindowResizeConsumer>
        )
    }
}

export default AuthLoadingScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.gray0,
    },
})
