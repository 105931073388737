import React from 'react'
import { StyleSheet, Image, Text, View } from 'react-native'
import { Linking } from 'expo'
import { Colors, Buttons, Layout, TextStyles, Icons } from '../constants'
import { UserProfileConsumer } from '../context/UserProfileContext'
import Button from './Button'
import ButtonIcon from './ButtonIcon'

export default class Footer extends React.Component {
    render() {
        return (
            <View
                style={{
                    flex: 1,
                    width: '100%',
                    justifyContent: 'flex-end',
                    backgroundColor: Colors.white,
                }}
            >
                <View style={Layout.footer}>
                    {Layout.isMediumDevice ? (
                        <View />
                    ) : (
                        <View
                            style={{
                                flex: 1,
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <ButtonIcon
                                iconName={'instagram'}
                                iconSize={Icons.medium}
                                iconColor={Colors.tintColor}
                                buttonStyle={[
                                    Buttons.menuButtonTransparentBG,
                                    {
                                        marginRight: Layout.paddingMedium,
                                    },
                                ]}
                                navigation={this.props.navigation}
                                onPress={() =>
                                    Linking.openURL(
                                        'https://www.instagram.com/foodiststudio/'
                                    )
                                }
                            />
                            <Button
                                navigation={this.props.navigation}
                                onPress={() =>
                                    this.props.navigation.navigate('Terms')
                                }
                                buttonStyle={[
                                    Buttons.buttonUnderline,
                                    // {
                                    //     marginHorizontal:
                                    //         Layout.paddingMedium,
                                    // },
                                ]}
                                textColor={Colors.tintColor}
                                textStyle={{
                                    textDecorationLine: 'underline',
                                }}
                                title={'Terms'}
                            />
                            <Button
                                navigation={this.props.navigation}
                                onPress={() =>
                                    this.props.navigation.navigate('Privacy')
                                }
                                buttonStyle={[
                                    Buttons.buttonUnderline,
                                    {
                                        marginHorizontal: Layout.paddingMedium,
                                    },
                                ]}
                                textColor={Colors.tintColor}
                                textStyle={{
                                    textDecorationLine: 'underline',
                                }}
                                title={'Privacy'}
                            />
                        </View>
                    )}
                    <View
                        style={{
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            paddingBottom: Layout.paddingLarge,
                            // margin: Layout.paddingSmall,
                            // padding: Layout.paddingSmall,
                        }}
                    >
                        <Image
                            source={require('../assets/images/icon.png')}
                            style={{
                                width: Layout.isMediumDevice
                                    ? Icons.XL
                                    : Icons.XXL,
                                height: Layout.isMediumDevice
                                    ? Icons.XL
                                    : Icons.XXL,
                            }}
                            resizeMode={'cover'}
                        />
                    </View>

                    <View
                        style={{
                            flex: 1,
                            alignItems: Layout.isMediumDevice
                                ? 'center'
                                : 'flex-end',
                            justifyContent: 'center',
                        }}
                    >
                        <Text style={TextStyles.Caption}>
                            © 2020 Indvstry Inc.
                        </Text>
                    </View>
                </View>
            </View>
        )
    }
}
