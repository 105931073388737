//import '@expo/match-media'
//import { useMediaQuery } from 'react-responsive'

import { Dimensions } from 'react-native'
import Colors from './Colors'

/**
 * https://github.com/evanbacon/react-responsive#with-hooks
 **/

// const Desktop = ({ children }) => {
//     const isDesktop = useMediaQuery({ minWidth: 992 })
//     return isDesktop ? children : null
// }
// const Tablet = ({ children }) => {
//     const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
//     return isTablet ? children : null
// }
// const Mobile = ({ children }) => {
//     const isMobile = useMediaQuery({ maxWidth: 767 })
//     return isMobile ? children : null
// }
// const Default = ({ children }) => {
//     const isNotMobile = useMediaQuery({ minWidth: 768 })
//     return isNotMobile ? children : null
// }

const SCREEN_HEIGHT = Dimensions.get('window').height
const SCREEN_WIDTH = Dimensions.get('window').width

/* Tab bar heights */
// https://github.com/react-navigation/react-navigation-tabs/blob/d2aa789109ed0df61c0c0ac7b759ac386a720804/src/views/BottomTabBar.js#L239

const TAB_DEFAULT_HEIGHT = 49
const TAB_COMPACT_HEIGHT = 29

const topNavHeightMobile = 70
const topNavHeightDesktop = 88

const isSmallDevice = SCREEN_WIDTH <= 414 // 414
const isMediumDevice = SCREEN_WIDTH <= 1024 // 768 // original
const isTabletDevice = SCREEN_WIDTH <= 1024 //991 // original

//const isMediumDevice = Default //Tablet //SCREEN_WIDTH <= 768){Mobile}else{ !Default}

const paddingSmall = 8
const paddingMedium = 16
const paddingLarge = 32
const paddingXL = 64
const paddingXXL = 128

const borderRadiusSmall = 3
const borderRadiusMedium = 6
const borderRadiusLarge = 9
const borderRadiusXL = 12
const borderRadiusXXL = 24
const borderRadiusMax = 100

export default {
    // Desktop,
    // Tablet,
    // Mobile,
    // Default,

    SCREEN_HEIGHT,
    SCREEN_WIDTH,

    TAB_DEFAULT_HEIGHT,
    TAB_COMPACT_HEIGHT,

    topNavHeightMobile,
    topNavHeightDesktop,

    isSmallDevice,
    isMediumDevice,
    isTabletDevice,

    paddingSmall,
    paddingMedium,
    paddingLarge,
    paddingXL,
    paddingXXL,

    borderRadiusSmall,
    borderRadiusMedium,
    borderRadiusLarge,
    borderRadiusXL,
    borderRadiusXXL,
    borderRadiusMax,

    window: {
        SCREEN_HEIGHT,
        SCREEN_WIDTH,
    },

    /* Containers */
    drawerContainer: {
        width: isMediumDevice ? SCREEN_WIDTH : '100%',
        minHeight: SCREEN_HEIGHT,
        paddingTop: topNavHeightMobile,
    },

    basicContainer: {
        padding: paddingLarge,
        alignItems: 'center',
        justifyContent: 'center',
    },

    bannerHeaderContainer: {
        //minHeight: isMediumDevice ? SCREEN_HEIGHT * 0.618 : 480,
        //height: SCREEN_HEIGHT <= 480 ? SCREEN_HEIGHT : SCREEN_HEIGHT * 0.618,
        marginTop: isTabletDevice ? topNavHeightMobile : topNavHeightDesktop,
        height: isTabletDevice ? SCREEN_HEIGHT * 0.382 : SCREEN_WIDTH * 0.236,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.white,
    },

    sectionContainer: {
        minHeight: isMediumDevice ? SCREEN_HEIGHT : 640,
        height: isMediumDevice || SCREEN_HEIGHT <= 640 ? null : SCREEN_HEIGHT,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.white,
    },

    sectionHeaderContainer: {
        minHeight: isMediumDevice ? SCREEN_HEIGHT * 0.618 : 480,
        height:
            isMediumDevice || SCREEN_HEIGHT <= 480
                ? null
                : SCREEN_HEIGHT * 0.618,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.white,
    },

    footer: {
        flexDirection: isMediumDevice ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: paddingLarge,
    },

    sectionFooterContainer: {
        minHeight: isMediumDevice ? SCREEN_HEIGHT * 0.5 : 480,
        height:
            isMediumDevice || SCREEN_HEIGHT <= 640 ? null : SCREEN_HEIGHT * 0.5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.white,
    },

    projectContainer: {
        //minHeight: isTabletDevice ? SCREEN_HEIGHT * 0.618 : SCREEN_HEIGHT,
        minHeight: SCREEN_HEIGHT,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.white,
        paddingVertical: isMediumDevice
            ? topNavHeightMobile
            : topNavHeightDesktop,
    },

    projectFooterContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.white,
    },

    contentOverlay: {
        flex: 1,
        width: SCREEN_WIDTH,
        minHeight: SCREEN_HEIGHT * 0.382,
        backgroundColor: Colors.mediumBackground,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: isMediumDevice
            ? topNavHeightMobile
            : topNavHeightDesktop,
        paddingHorizontal: paddingLarge,
    },

    contentOverlayImage: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.mediumBackground,
        paddingHorizontal: isMediumDevice ? paddingLarge : paddingXL,
    },

    contentOverlayHero: {
        flex: 1,
        width: SCREEN_WIDTH,
        minHeight: SCREEN_HEIGHT * 0.382,
        backgroundColor: Colors.lightBackground,
        paddingHorizontal: isMediumDevice ? paddingLarge : paddingXL,
    },

    cardContent: {
        maxWidth: 1440,
        minWidth: isMediumDevice ? '100%' : 360,
        padding: paddingLarge,
        paddingBottom: paddingXL,
        backgroundColor: Colors.white,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    cardResearchMethods: {
        maxWidth: 360,
        minWidth: isSmallDevice ? '100%' : 360,
        padding: paddingLarge,
        paddingBottom: paddingXL,
        backgroundColor: Colors.white,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    cardCosmo: {
        maxWidth: 360,
        minWidth: isSmallDevice ? '100%' : 360,
        minHeight: 320,
        padding: 0,
        overflow: 'hidden',
        backgroundColor: Colors.white,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    cardTooltip: {
        paddingVertical: paddingSmall,
        paddingHorizontal: paddingMedium,
        zIndex: 100,
        backgroundColor: Colors.white,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    mockHeroContainer: {
        overflow: 'hidden',
        borderRadius: borderRadiusXL,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.lightBackground,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    mockHeroPoster: {
        width: isMediumDevice
            ? SCREEN_WIDTH - paddingXL
            : SCREEN_HEIGHT * 0.382,
        height: isMediumDevice ? SCREEN_HEIGHT * 0.8 : SCREEN_HEIGHT * 0.618,
    },

    mockWebContainer: {
        overflow: 'hidden',

        borderRadius: borderRadiusLarge,
        borderColor: Colors.gray0,
        backgroundColor: Colors.tintColor,
        borderWidth: 4,
        alignItems: 'center',
        justifyContent: 'flex-start',
        alignSelf: 'center',

        padding: isMediumDevice ? paddingMedium : paddingLarge,

        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    mockWebVideo: {
        width: isMediumDevice
            ? SCREEN_HEIGHT * 0.618 * 0.583
            : SCREEN_WIDTH * 0.8,
        height: isMediumDevice
            ? SCREEN_HEIGHT * 0.618
            : SCREEN_WIDTH * 0.8 * 0.625,
        alignSelf: 'center',
    },

    mockWebPoster: {
        zIndex: -10,
        width: isMediumDevice
            ? SCREEN_HEIGHT * 0.618 * 0.583
            : SCREEN_WIDTH * 0.8,
        height: isMediumDevice
            ? SCREEN_HEIGHT * 0.618
            : SCREEN_WIDTH * 0.8 * 0.625,
    },

    mockWebAppContainer: {
        width: isMediumDevice
            ? SCREEN_HEIGHT * 0.618 * 0.52
            : SCREEN_HEIGHT * 0.8 * 0.52,
        height: isMediumDevice ? SCREEN_HEIGHT * 0.618 : SCREEN_HEIGHT * 0.8,
        overflow: 'hidden',

        borderRadius: 26,
        borderColor: Colors.gray0,
        backgroundColor: Colors.black,
        borderWidth: 6,
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',

        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    mockWebAppVideo: {
        width: isMediumDevice
            ? SCREEN_HEIGHT * 0.618 * 0.52
            : SCREEN_HEIGHT * 0.8 * 0.52,
        height: isMediumDevice ? SCREEN_HEIGHT * 0.618 : SCREEN_HEIGHT * 0.8,
        alignSelf: 'center',
    },

    mockWebAppPoster: {
        zIndex: -10,
        width: isMediumDevice
            ? SCREEN_HEIGHT * 0.618 * 0.52
            : SCREEN_HEIGHT * 0.8 * 0.52,
        height: isMediumDevice ? SCREEN_HEIGHT * 0.618 : SCREEN_HEIGHT * 0.8,
    },

    mockAppContainer: {
        width: isMediumDevice
            ? SCREEN_HEIGHT * 0.618 * 0.46
            : SCREEN_HEIGHT * 0.8 * 0.46,
        height: isMediumDevice ? SCREEN_HEIGHT * 0.618 : SCREEN_HEIGHT * 0.8,
        overflow: 'hidden',

        borderRadius: 26,
        borderColor: Colors.gray0,
        backgroundColor: Colors.black,
        borderWidth: 6,
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',

        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    mockAppVideo: {
        width: isMediumDevice
            ? SCREEN_HEIGHT * 0.618 * 0.46
            : SCREEN_HEIGHT * 0.8 * 0.46,
        height: isMediumDevice ? SCREEN_HEIGHT * 0.618 : SCREEN_HEIGHT * 0.8,
        alignSelf: 'center',
    },

    mockAppPoster: {
        zIndex: -10,
        width: isMediumDevice
            ? SCREEN_HEIGHT * 0.618 * 0.46
            : SCREEN_HEIGHT * 0.8 * 0.46,
        height: isMediumDevice ? SCREEN_HEIGHT * 0.618 : SCREEN_HEIGHT * 0.8,
    },

    /* Columns */
    columnContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'center',
    },

    columnContainerReverse: {
        flexDirection: isMediumDevice ? 'column-reverse' : 'row',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'center',
    },

    columnOne: {
        flex: 1,
        paddingHorizontal: paddingLarge,
        paddingVertical: paddingLarge,
        // paddingVertical: isMediumDevice
        //     ? topNavHeightMobile
        //     : topNavHeightDesktop,
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 1440,
        width: '100%',
    },

    columnTwo: {
        flex: 1,
        paddingHorizontal: paddingLarge,
        paddingVertical: paddingLarge,
        maxWidth: isMediumDevice ? SCREEN_WIDTH : 720,
        minWidth: isMediumDevice ? '100%' : 360,
        width: isMediumDevice ? '100%' : SCREEN_WIDTH * 0.5,
    },

    columnThree: {
        flex: 1,
        paddingHorizontal: paddingLarge,
        paddingVertical: paddingLarge,
        //maxWidth: isMediumDevice ? SCREEN_WIDTH : 480,
        //maxWidth: isMediumDevice ? SCREEN_WIDTH : '100%',
        minWidth: isMediumDevice ? '100%' : 360,
        //width: isMediumDevice ? '100%' : SCREEN_WIDTH * 0.333,
        width: '100%',
    },

    columnTwoImages: {
        flex: 1,
        minWidth: isMediumDevice ? '100%' : 360,
        width: isMediumDevice ? '100%' : SCREEN_WIDTH * 0.5,
        height: isMediumDevice ? '100%' : SCREEN_HEIGHT,
    },

    columnThreeImages: {
        flex: 1,
        minWidth: isMediumDevice ? '100%' : 360,
        //width: isMediumDevice ? '100%' : SCREEN_WIDTH * 0.33333,
        width: '100%',
        height: SCREEN_HEIGHT,
    },

    /* Images */
    homeHeroImage: {
        backgroundColor: Colors.accentYellow,
        width: isTabletDevice
            ? '100%'
            : SCREEN_WIDTH < SCREEN_HEIGHT
            ? SCREEN_HEIGHT * 0.382 * 0.75
            : SCREEN_WIDTH * 0.382 * 0.75,
        height: isTabletDevice
            ? '100%'
            : SCREEN_WIDTH < SCREEN_HEIGHT
            ? SCREEN_HEIGHT * 0.382
            : SCREEN_WIDTH * 0.382,
    },

    heroImage: {
        maxWidth: isMediumDevice ? SCREEN_WIDTH - paddingLarge * 2 : 1440,
        minWidth: isMediumDevice
            ? SCREEN_WIDTH - paddingLarge * 2
            : SCREEN_WIDTH <= 1440
            ? SCREEN_WIDTH - paddingLarge * 2
            : 1440,
        height: isMediumDevice
            ? SCREEN_HEIGHT * 0.618
            : SCREEN_HEIGHT - topNavHeightDesktop * 2,
        maxHeight: isMediumDevice ? SCREEN_HEIGHT * 0.618 : 960,
    },

    selectedWorkHeroImage: {
        width: '100%',
        height: isMediumDevice
            ? SCREEN_HEIGHT * 0.618
            : SCREEN_WIDTH * 0.382 /* add a || screen dimensions  */,
        maxHeight: 720,
    },

    avatarImageCircle: {
        overflow: 'hidden',
        borderRadius: 96,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        width: 56,
        height: 56,
    },

    iconImageCircle: {
        overflow: 'hidden',
        backgroundColor: Colors.gray0,
        borderRadius: 96,
        alignSelf: 'flex-start',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: paddingMedium,
        marginBottom: paddingLarge,
        padding: paddingLarge,
    },

    iconImageTeamCircle: {
        overflow: 'hidden',
        backgroundColor: Colors.accentYellow,
        borderRadius: 96,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: paddingMedium,
        marginBottom: paddingLarge,
        padding: paddingLarge,
    },

    iconImageCircleCaesars: {
        overflow: 'hidden',
        backgroundColor: '#C00000',
        borderRadius: 96,
        alignSelf: 'flex-start',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: paddingMedium,
        marginBottom: paddingLarge,
        padding: paddingLarge,
    },

    iconImageTintCircle: {
        overflow: 'hidden',
        backgroundColor: Colors.tintColor,
        borderRadius: 96,
        alignSelf: 'flex-start',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: paddingMedium,
        marginBottom: paddingLarge,
        padding: paddingLarge,
    },

    /* Charts */
    chartBubbleMargins: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },

    chartHeroMargins: {
        top: paddingLarge,
        right: paddingLarge,
        bottom: paddingLarge,
        left: paddingLarge,
    },

    chartMargins: {
        top: paddingLarge,
        right: paddingLarge,
        bottom: paddingLarge,
        left: paddingLarge,
    },

    chartBubbleContainer: {
        height: SCREEN_HEIGHT * 0.618,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },

    chartHeroContainer: {
        height: SCREEN_HEIGHT * 0.618,
        width: SCREEN_WIDTH,
        maxWidth: 1440,
        alignItems: 'center',
        justifyContent: 'center',
    },

    chartContainer: {
        height: SCREEN_HEIGHT * 0.5,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },

    /* New components */

    eventDateWidget: {
        alignItems: 'center',
        borderRadius: borderRadiusLarge,
        paddingVertical: paddingSmall,
        paddingHorizontal: paddingSmall,
        width: 50,
        backgroundColor: Colors.white,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffset,
        shadowOpacity: Colors.shadowOpacity,
        shadowRadius: Colors.shadowRadius,
        elevation: Colors.elevation,
    },
}
