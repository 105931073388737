import React from 'react'
import { ScrollView, View, Text, ImageBackground } from 'react-native'
import { Linking } from 'expo'
import { LinearGradient } from 'expo-linear-gradient'
import { Colors, Buttons, TextStyles, Layout } from '../../constants'
import Button from '../../components/Button'
import Footer from '../../components/Footer'

export default class Contact extends React.Component {
    componentDidMount = async () => {
        await this.props.navigation.setParams({
            active: true,
        })
    }

    render() {
        return (
            <View
                style={{
                    flex: 1,
                }}
            >
                <ScrollView style={{ flex: 1 }}>
                    <LinearGradient
                        start={[0.0, 0.0]}
                        end={[1.0, 1.0]}
                        colors={[Colors.sunshine, Colors.coral]}
                        style={[
                            Layout.projectContainer,
                            {
                                minHeight: Layout.SCREEN_HEIGHT,
                                backgroundColor: Colors.gray0,
                            },
                        ]}
                    >
                        {/* <ImageBackground
                            source={require('../../assets/images/annie-spratt-Iqwnr00mfQM-unsplash.jpg')}
                            style={[
                                Layout.projectContainer,
                                {
                                    minHeight: Layout.SCREEN_HEIGHT,
                                    backgroundColor: Colors.gray0,
                                },
                            ]}
                        > */}
                        <View style={Layout.columnOne}>
                            <View
                                style={[
                                    Layout.cardContent,
                                    {
                                        alignItems: 'center',
                                        paddingTop: Layout.paddingXL,
                                        maxWidth: 480,
                                    },
                                ]}
                            >
                                <Text
                                    style={[
                                        TextStyles.Heading1,
                                        {
                                            paddingBottom: Layout.paddingMedium,
                                        },
                                    ]}
                                >
                                    Get in touch
                                </Text>
                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            paddingBottom: Layout.paddingLarge,
                                            textAlign: 'center',
                                        },
                                    ]}
                                >
                                    Talk to a team member who can take you
                                    through everything Foodist Studio has to
                                    offer. We're here to help.
                                </Text>
                                <Button
                                    iconLeft={true}
                                    iconName={'mail'}
                                    onPress={() =>
                                        Linking.openURL(
                                            'mailto: julian@foodist.studio'
                                        )
                                    }
                                    //textColor={Colors.tintColor}
                                    buttonStyle={[
                                        //Buttons.buttonOutline,

                                        {
                                            alignSelf: 'center',
                                            width: '100%',
                                        },
                                    ]}
                                    title={'Email us'}
                                />

                                {/* <View
                                style={{
                                    flexDirection: Layout.isMediumDevice
                                        ? 'column'
                                        : 'row',
                                    alignItems: 'center',
                                    paddingTop: Layout.isMediumDevice
                                        ? Layout.paddingMedium
                                        : Layout.paddingLarge,
                                }}
                            >
                                <Button
                                    navigation={this.props.navigation}
                                    onPress={() =>
                                        Linking.openURL('https://twitter.com/')
                                    }
                                    buttonStyle={[
                                        Buttons.buttonUnderline,
                                        {
                                            marginHorizontal:
                                                Layout.paddingSmall,
                                        },
                                    ]}
                                    textColor={Colors.tintColor}
                                    textStyle={{
                                        textDecorationLine: 'underline',
                                    }}
                                    title={'Twitter'}
                                />

                                <Button
                                    navigation={this.props.navigation}
                                    onPress={() =>
                                        Linking.openURL(
                                            'https://instagram.com/'
                                        )
                                    }
                                    buttonStyle={[
                                        Buttons.buttonUnderline,
                                        {
                                            marginHorizontal:
                                                Layout.paddingSmall,
                                        },
                                    ]}
                                    textColor={Colors.tintColor}
                                    textStyle={{
                                        textDecorationLine: 'underline',
                                    }}
                                    title={'Instagram'}
                                />
                            </View>
                       
                        */}
                            </View>
                        </View>
                        {/* </ImageBackground> */}
                    </LinearGradient>
                    <Footer navigation={this.props.navigation} />
                </ScrollView>
            </View>
        )
    }
}
