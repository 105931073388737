import React from 'react'
import { ScrollView, Text, View } from 'react-native'
import { Feather } from '@expo/vector-icons'
import { Colors, Icons, TextStyles, Layout } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import Button from '../../components/Button'

export default class RequestConfirmation extends React.Component {
    componentDidMount() {
        this.context.resetUserForms()
    }

    render() {
        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <ScrollView
                                style={{
                                    flex: 1,
                                    backgroundColor: Colors.gray0,
                                }}
                            >
                                <View
                                    style={[
                                        Layout.sectionContainer,
                                        { backgroundColor: Colors.gray0 },
                                    ]}
                                >
                                    <View style={Layout.columnOne}>
                                        <View style={Layout.cardContent}>
                                            <View
                                                style={{
                                                    height: Icons.XL * 2,
                                                    width: Icons.XL * 2,
                                                    borderRadius: Icons.XL,
                                                    borderWidth: 2,
                                                    borderColor:
                                                        Colors.tintColor,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    alignSelf: 'center',
                                                    marginVertical:
                                                        Layout.paddingMedium,
                                                }}
                                            >
                                                <Feather
                                                    style={{
                                                        alignSelf: 'center',
                                                    }}
                                                    color={Colors.tintColor}
                                                    size={Icons.XL}
                                                    name={'check'}
                                                />
                                            </View>
                                            <Text
                                                style={[
                                                    TextStyles.Heading1,
                                                    {
                                                        textAlign: 'center',
                                                        paddingBottom:
                                                            Layout.paddingMedium,
                                                    },
                                                ]}
                                            >
                                                Thanks for signing up
                                            </Text>
                                            <Text
                                                style={[
                                                    TextStyles.Paragraph,
                                                    {
                                                        paddingBottom:
                                                            Layout.paddingLarge,
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            >
                                                We’ll keep you updated on the
                                                latest news at Indvstry.
                                            </Text>
                                            <Button
                                                style={{
                                                    alignSelf: 'center',
                                                }}
                                                title={'Back to home'}
                                                onPress={() => {
                                                    this.props.navigation.navigate(
                                                        'AuthHome'
                                                    )
                                                }}
                                            />
                                        </View>
                                    </View>
                                </View>
                            </ScrollView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}
