import React from 'react'
import { View, Text } from 'react-native'
import { Video } from 'expo-av'
import { Linking } from 'expo'
import { Layout, TextStyles, Colors, Buttons, Icons } from '../constants'
import Button from './Button'
import ButtonIcon from './ButtonIcon'

export default class ProductExplorerWebApp extends React.Component {
    state = {
        isLoaded: false,
        //isBuffering: false,
        //video: null,
    }

    // componentDidMount = async () => {
    //     // const poster = Layout.isMediumDevice
    //     // ? require('../assets/images/indvstry-website-mobile-poster.jpg')
    //     // : require('../assets/images/indvstry-website-desktop-poster.jpg')

    //     const video = Layout.isMediumDevice
    //         ? require('../assets/videos/indvstry-website-mobile.mp4')
    //         : require('../assets/videos/indvstry-website-desktop.mp4')

    //     this.setState({
    //         //poster: poster,
    //         video: video,
    //     })
    // }

    _onPlaybackStatusUpdate = (status) => {
        if (status.isLoaded) {
            this.setState({
                isLoaded: status.isLoaded,

                // playbackInstancePosition: status.positionMillis,
                // playbackInstanceDuration: status.durationMillis,
                // shouldPlay: status.shouldPlay,
                // isPlaying: status.isPlaying,
                //isBuffering: status.isBuffering,
                // rate: status.rate,
                // muted: status.isMuted,
                // volume: status.volume,
                // loopingType: status.isLooping
                //     ? LOOPING_TYPE_ONE
                //     : LOOPING_TYPE_ALL,
                // shouldCorrectPitch: status.shouldCorrectPitch,
            })
            console.log(this.state)
            // } else {
            //     if (status.error) {
            //         console.log(`FATAL PLAYER ERROR: ${status.error}`)
            //     }
        }
    }

    render() {
        const poster = require('../assets/images/indvstry-webapp-poster.jpg')
        const video = require('../assets/videos/indvstry-webapp-mobile.mp4')

        // const video = Layout.isMediumDevice
        //     ? 'https://firebasestorage.googleapis.com/v0/b/mauricekenji-com.appspot.com/o/videos%2Findvstry-website-mobile.mp4?alt=media&token=b6982bf2-a95a-41e2-b69b-5e8686fbba6f'
        //     : 'https://firebasestorage.googleapis.com/v0/b/mauricekenji-com.appspot.com/o/videos%2Findvstry-website-desktop.mp4?alt=media&token=ce9c5764-886b-49f4-b8f3-a3644db655d2'

        return (
            <View
                style={[
                    Layout.projectContainer,
                    {
                        paddingBottom: Layout.paddingXXL,
                        backgroundColor: Colors.gray0,
                    },
                ]}
            >
                <View
                    style={[
                        Layout.columnContainerReverse,
                        { alignItems: 'center' },
                    ]}
                >
                    <View style={Layout.columnTwo}>
                        <View style={Layout.mockWebAppContainer}>
                            <Video
                                //onPlaybackStatusUpdate={this._onPlaybackStatusUpdate}
                                source={{
                                    uri: video,
                                    headers: { ['content-type']: 'video/mp4' },
                                }}
                                onLoad={(status) => {
                                    console.log(status)
                                }}
                                //source={{ uri: this.state.video }}
                                //initialStatusToSet={}
                                //downloadFirst={false}
                                //source={null}
                                // source={() =>
                                //     videoRef.loadAsync({ uri: video }, null, false)
                                // }
                                style={Layout.mockWebAppVideo}
                                resizeMode="contain"
                                isMuted
                                rate={1.0}
                                volume={0}
                                usePoster={true}
                                posterSource={{ uri: poster }}
                                posterStyle={Layout.mockWebAppPoster}
                                useNativeControls={
                                    Layout.isMediumDevice ? true : false
                                }
                                shouldPlay={
                                    Layout.isMediumDevice ? false : true
                                }
                                isLooping={Layout.isMediumDevice ? false : true}
                                // onLoad={
                                //     () => window.alert('loaded')

                                //     //await this.setState({ isLoaded: true })
                                //     //window.alert(this.state.isLoaded)
                                // }
                                // useNativeControls={
                                //     Layout.isMediumDevice ? this.state.isLoaded : false
                                // }
                            />
                        </View>
                    </View>

                    <View style={Layout.columnTwo}>
                        <Text
                            style={[
                                TextStyles.Heading1,
                                {
                                    paddingBottom: Layout.paddingMedium,
                                    textAlign: Layout.isMediumDevice
                                        ? 'center'
                                        : 'left',
                                },
                            ]}
                        >
                            Stories as distinctive as your dishes
                        </Text>
                        <Text
                            style={[
                                TextStyles.Paragraph,
                                {
                                    //paddingHorizontal: Layout.paddingLarge,
                                    paddingBottom: Layout.paddingLarge,
                                    textAlign: Layout.isMediumDevice
                                        ? 'center'
                                        : 'left',
                                },
                            ]}
                        >
                            Foodist Studio is here to help you create food and
                            drink content to engage your followers.
                        </Text>
                        {/* <ButtonIcon
                            iconName={'instagram'}
                            iconSize={Icons.medium}
                            iconColor={Colors.tintColor}
                            buttonStyle={[
                                Buttons.menuButtonTransparentBG,
                                {
                                    backgroundColor: Colors.gray0,
                                    alignSelf: 'flex-start',
                                },
                            ]}
                            navigation={this.props.navigation}
                            onPress={() =>
                                Linking.openURL(
                                    `https://www.instagram.com/foodiststudio/`
                                )
                            }
                        /> */}
                        <Button
                            //onPress={this.onPressExplore}
                            iconLeft={true}
                            iconName={'instagram'}
                            onPress={this.props.onPress}
                            buttonStyle={[
                                Buttons.buttonIcon,
                                {
                                    //minWidth: 160,
                                    alignSelf: Layout.isMediumDevice
                                        ? 'center'
                                        : 'flex-start',
                                },
                            ]}
                            title={'Follow Foodist Studio'}
                            onPress={() =>
                                Linking.openURL(
                                    `https://www.instagram.com/foodiststudio/`
                                )
                            }
                        />
                    </View>
                </View>
            </View>
        )
    }
}
