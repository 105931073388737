import React from 'react'
import { View, TouchableOpacity, Text } from 'react-native'
import { DrawerActions } from 'react-navigation-drawer'
import { Layout, Buttons, Colors, TextStyles, Icons } from '../constants'
import Button from './Button'
import ButtonIcon from './ButtonIcon'

export default class DrawerNavigationTabs extends React.Component {
    render() {
        return (
            <View style={Layout.drawerContainer}>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: Layout.paddingLarge,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <TouchableOpacity
                        onPress={() =>
                            this.props.navigation.navigate(
                                this.props.userExists
                                    ? 'PrivateHome'
                                    : 'AuthHome'
                            ) &&
                            this.props.navigation.dispatch(
                                DrawerActions.toggleDrawer()
                            )
                        }
                    >
                        <Text
                            style={[
                                TextStyles.Heading2Inverse,
                                {
                                    paddingBottom: Layout.paddingLarge,
                                },
                            ]}
                        >
                            Home
                        </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={() => {
                            this.props.navigation.navigate('Restaurants') &&
                                this.props.navigation.dispatch(
                                    DrawerActions.toggleDrawer()
                                )
                        }}
                    >
                        <Text
                            style={[
                                TextStyles.Heading2Inverse,
                                {
                                    paddingBottom: Layout.paddingLarge,
                                },
                            ]}
                        >
                            Restaurants
                        </Text>
                    </TouchableOpacity>
                    {/* <TouchableOpacity
                        onPress={() => {
                            this.props.navigation.navigate('About') &&
                                this.props.navigation.dispatch(
                                    DrawerActions.toggleDrawer()
                                )
                        }}
                    >
                        <Text
                            style={[
                                TextStyles.Heading2Inverse,
                                {
                                    paddingBottom: Layout.paddingLarge,
                                },
                            ]}
                        >
                            About
                        </Text>
                    </TouchableOpacity> */}
                    <TouchableOpacity
                        onPress={() => {
                            this.props.navigation.navigate('Contact') &&
                                this.props.navigation.dispatch(
                                    DrawerActions.toggleDrawer()
                                )
                        }}
                    >
                        <Text
                            style={[
                                TextStyles.Heading2Inverse,
                                {
                                    paddingBottom: Layout.paddingLarge,
                                },
                            ]}
                        >
                            Contact
                        </Text>
                    </TouchableOpacity>

                    {/* {this.props.userExists ? (
                        <TouchableOpacity
                            onPress={() => {
                                this.props.navigation.navigate(
                                    'IndvstryProject'
                                ) &&
                                    this.props.navigation.dispatch(
                                        DrawerActions.toggleDrawer()
                                    )
                            }}
                        >
                            <Text
                                style={[
                                    TextStyles.Heading2Inverse,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                Our vision
                            </Text>
                        </TouchableOpacity>
                    ) : (
                        <View />
                    )} */}
                </View>
                <View
                    style={{
                        flexShrink: 1,
                        borderTopColor: Colors.inverseIconDisabled,
                        borderTopWidth: 1,
                        padding: Layout.paddingLarge,
                        alignItems: 'flex-start',
                    }}
                >
                    <ButtonIcon
                        iconName={'instagram'}
                        iconSize={Icons.medium}
                        iconColor={Colors.white}
                        buttonStyle={[
                            Buttons.menuButtonTransparentBG,
                            {
                                paddingLeft: 0,
                            },
                        ]}
                        navigation={this.props.navigation}
                        onPress={() =>
                            Linking.openURL(
                                'https://www.instagram.com/foodiststudio/'
                            )
                        }
                    />
                    <Button
                        navigation={this.props.navigation}
                        onPress={() =>
                            this.props.navigation.navigate('Terms') &&
                            this.props.navigation.dispatch(
                                DrawerActions.toggleDrawer()
                            )
                        }
                        buttonStyle={[
                            Buttons.buttonUnderline,
                            { paddingHorizontal: 0 },
                        ]}
                        textColor={Colors.white}
                        textStyle={{
                            textDecorationLine: 'underline',
                        }}
                        title={'Terms'}
                    />

                    <Button
                        navigation={this.props.navigation}
                        onPress={() =>
                            this.props.navigation.navigate('Privacy') &&
                            this.props.navigation.dispatch(
                                DrawerActions.toggleDrawer()
                            )
                        }
                        buttonStyle={[
                            Buttons.buttonUnderline,
                            { paddingHorizontal: 0 },
                        ]}
                        textColor={Colors.white}
                        textStyle={{
                            textDecorationLine: 'underline',
                        }}
                        title={'Privacy'}
                    />
                </View>
            </View>
        )
    }
}
